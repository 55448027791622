@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap);

html {
  box-sizing: border-box;
}


  *,
  *::before,
  *::after {
  box-sizing: inherit;
}


a {
  color:inherit;
  text-decoration: none;
}


img {
  max-width: 100%;
}


body {
  min-width: 320px;
  font-family: 'Inter', sans-serif;
  background-color: black;
  color: white;
}


:root{
  --gap: 20px;
}


/* glob */
.list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}


.btn-reset {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}


.flex {
  display: flex;
}


.container {
  max-width: 1420px;
  padding: 0px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 auto;
  width: 100%;
}


.section-title {
  margin: 0;
  margin-bottom: 40px;
  font-size: 64px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}


.section-descr {
margin: 0;
margin-bottom: 50px;
}


.section-offset {
padding-top: 87px;
padding-bottom: 160px;
/* background-image: url(../../src/img/photo_gallery/phglr_1.jpg); */
}


.page-title{
margin: 0;
font-size: 28px;
padding-bottom: 40px;
}

/* glob */

/* header */
.header {
  /* position: fixed; */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  padding-top: 50px;
  width: 100%;
  z-index: 1000;
  display: flex; /* Добавляем flex-контейнер */
  justify-content: center; /* Центрируем содержимое по горизонтали */
}

.header-container {
  min-width: 320px;
  max-height: 100px;
  align-items: center;
  padding: 16px 40px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.001);
  border-radius: 500px;
  backdrop-filter: blur(5px);
  justify-content: space-between;
}

.header-nav {
  align-items: center;
}

.header-list-contacts {
  display: none;
}

.header-logo {
  align-items: center;
}

.alt-logo {
  display: none;
}

.header-list {
  width: 986px;
  justify-content: space-between;
  font-size: 20px;
}

.burger {
  display: none;
  cursor: pointer;
}

.header-item-txt {
  position: relative;
  cursor: pointer;
}

.header-item-txt::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  /* top: 5px; */
  width: 100%;
  height: 1px;
  background-color:white;
  transform: scaleX(0);
  transition: transform 0.3s;
}

.header-list-item {
  cursor: pointer!important;
}

.header-item-txt:hover::after {
  transform: scaleX(1);
}

@media (max-width: 1272px) {
  .header-nav {
    display: none;
  }
  
  .header-nav.active {
    position: fixed;
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 185vw;
    left: 0;
    top: 99px;
    z-index: 1000;
    background-color: black;
    color: #F4F4F4;
    animation: navAnimation 0.3s;
    background-image: linear-gradient(to bottom, black 10%, transparent 100%), url("../img/Gradient.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 250%;
    margin: 0;
    padding: 0;
  }

  .header-nav.active ul li {
    font-size: 35px;
  }

  .header-list {
    padding-top: 5vw;
  }

  .header-list-contacts {
    display: block;
    text-align: center;
  }

  .header-item-txt {
    font-size: 35px;
    text-transform: uppercase;
  }

  .header-nav.active ul {
    align-items: center;
  }
  .header-list {
    flex-direction: column;
    row-gap: 30px;
  }
  
  @keyframes navAnimation {
    from {opacity: 0}
    to {opacity: 1}
  }

  .burger {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    min-width: 60px;
    height: 60px;
    color: white;
    z-index: 50;
  }

  .burger-line {
    background-color: currentColor;
    transform: scale(1);
    width: 80%;
    height: 2px;
    border-radius: inherit;
  }

  .burger::before, .burger::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 80%;
    background-color: currentColor;
    transition: all 0.3s;
  }

  .burger::before { top: 15px }
  .burger::after { bottom: 15px }

  .burger.active span {
    transform: scale(0);
    transition: 0.3s ease-in-out;
  }

  .burger span {
    transition: 0.3s ease-in-out;
  }

  .burger.active::before { 
    top: 50%;
    transform: rotate(45deg) translate(0,-50%);
    transition: 0.3s ease-in-out;
  }
  .burger.active::after {
    top: 50%;
    transform: rotate(-45deg) translate(0,50%);
    transition: 0.3s ease-in-out;
  }

  .header-list-item {
    display: flex;
    justify-content: center;
  }

  .header-list-item a {
    text-align: center;
  }
}

.no-scroll {
  overflow: hidden;
}

@media (max-width:540px) {
  .header-item-txt::after {
    display: none;
  }
  
  .header-list-contacts ul li{
    font-size: 16px!important;
    width: 70%;
  }

  .header-list-contacts h3 {
    margin-top: 0;
  }

  .header-item-txt {
    font-size: 22px;
  }

  .advantages-title-part {
    padding-left: 10vw!important;
  }
}

@media (max-height: 450px) {
  .header-nav,
  .header-nav.active {
    max-height: 65vh;
    overflow-y: auto;
  }
}

.description {
  height: 900px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 40px;
  /* background-image: linear-gradient(to bottom, black 0%, transparent 100%); */
}

.description-btn-sec {
  justify-content: center;
  margin-top: 62px;
}

.description-item {
  font-size: 88px;
  font-weight: 400;
  text-transform: uppercase;
}

.description-item.second {
  padding-left: 5.21vw;
}

.description-item.third {
  padding-left: 10.42vw;
}

.description-item.fourth {
  padding-left: 18.80vw;
}

.description-item.sixth {
  padding-left: 24.22vw;
}

.description-btn {
  font-size: 30px;
  text-transform: uppercase;
  color: white;
  padding: 20px 69px;
  border: 2px solid white;
  border-radius: 100px;
  display: flex;
}

.arrow {
  transition: 0.3s ease-in-out;
  margin-left: 10px;
}

.arrow.active {
  transform: rotate(-45deg);
}

.description-add {
  max-width: 1172px;
  /* height: 686px; */
  text-transform: uppercase;
  font-size: 60px;
  text-align: center;
  padding: 140px 0 140px 0;
  transition: 0.5s ease-in-out;
}

@media (max-width: 1440px) {
  .description-item {
    font-size: 68px;
  }
  .description {
    padding-top: 50px;
  }
}


@media (max-width: 1272px) {
  .description {
    padding-top: 50px;
  }

  .description-item,
  .section-title {
    font-size: 64px;
  }

  .facecast.active {
    display: none;
  }

  .description-add {
    font-size: 36px;
  }
}

@media (max-width: 992px) {
  .description-item,
  .section-title {
    font-size: 48px;
  }

  .description-btn {
    font-size: 24px;
  }
}

@media (max-width: 710px) {
  .description-item{
    font-size: 38px;
  }
}


@media (max-width: 540px) {
  .description {
    padding-top: 100px;
    height: 550px;
  }

  .description-item.third {
    padding-left: 5.21vw;
  }

  .description-item {
    font-size: 26px;
  }

  .description-add {
    font-size: 22px
  }

  .description-btn-sec {
    align-items: center;
  }

  .description-btn {
    padding: 12px 40px;
    font-size: 18px;
  }

  .arrow {
    width: 10%;
  }
}

@media (max-width: 400px) {
  .description-item {
    font-size: 22px!important;
  }

  .ecosystem-add::after {
    transform: scale(55%)!important;
  }
}




/* products */
.products-section {
  position: relative;
}

.products-section::before,
.products-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  background-image: url('../img/Background-1.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.products-section::before {
  background-position: center top;
}

.products-section::after {
  top: auto;
  bottom: 0;
  /* background-position: center bottom; */
  transform: rotate(180deg);
  background-position: center;

}

.products {
  height: 1921px;
  background-repeat: no-repeat;
  background-position: top;
}

.products-title {
  padding-top: 373px;
}

.products-list {
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--gap);
  --offsets: 1;
  padding-top: 108px;
}

.products-item {
  height: 312px;
  width: calc((100% - (var(--gap) * var(--offsets))) / (var(--offsets) + 1));
  border: 1px solid white;
  padding: 34px 0 34px 32px;
  font-size: 36px;
  z-index: 10;
  transition: 0.3s ease-in-out;
}

.products-item.active:hover {
  background-image: linear-gradient(to top, #1e568e, transparent);
  transition: 0.3s ease-in-out;
}

.mr:hover {
  background-image: linear-gradient(to top, rgb(68,61,156), transparent)!important;
  transition: 0.3s ease-in-out;
}

.or:hover {
  background-image: linear-gradient(to top, rgb(112,59,154), transparent)!important;
  transition: 0.3s ease-in-out;
}

.prod:hover {
  background-image: linear-gradient(to top, rgb(58,120,154), transparent)!important;
  transition: 0.3s ease-in-out;
}

.ev:hover {
  background-image: linear-gradient(to top, rgb(10,71,133), transparent)!important;
  transition: 0.3s ease-in-out;
}

.null_item {
  border: none;
  cursor: auto;
}

.products-item-name {
  cursor: pointer;
  padding-top: 160px;
  height: auto;
  z-index: 2;
  width: 80%;
}



@media screen and (max-width: 992px) {
  .products-list {
    --offsets: 0;
  }

  .null_item {
    display: none;
  }

  .products-section {
    height: 300vh;
  }
}

@media (max-height: 450px) {
  .products-section {
    height: 600vh;
  }
}

@media (max-height: 380px) {
  .products-section {
    height: 650vh;
  }
}

@media (max-width: 540px) {
  .products-title {
    font-size: 28px;
    padding-top: 100px;
    padding-bottom: 20px;
    margin: 0;
    z-index: 1000;
  }

  .products-list {
    padding: 0 10px 0 10px;
  }

  .products-section::before,
  .products-section::after {
    height: 25%;
  }

  .products-item {
    height: auto;
    padding: 16px 0 16px 16px;
  }

  .products-item-name {
    font-size: 22px;
    padding-top: 97px;
    background-size: 20%;
  }

  .products-section {
    height: 1250px;
  }

  .products {
    height: inherit;
  }

}


/* advantages */

.advantages {
  margin-left: 5vw;
  position: relative;
}

.advantages-title {
  padding-top: 5.21vw;
  flex-direction: column;
  justify-content: center;
}

/* .scroll-button {
  position: relative;
  z-index: 1;
} */

.scroll-left {
  background-image: url("../img/Vector\ 36.png");
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  height: 60px;
  width: auto;
}

.scroll-right {
  background-image: url("../img/Vector\ 37.png");
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  height: 60px;
  width: auto;
}

.left-rigth-btn {
  position: absolute;
  bottom: -25px;
}

.scroll-container {
  overflow-x: auto;
  overflow-y: hidden;
  padding: 50px;
  gap: var(--gap);
  margin-right: 50px;
  /* position: relative; */
}

.scroll-item {
  width: 710px; /* Ширина каждого элемента */
  height: 312px;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Стили для полосы прокрутки */
.scroll-container::-webkit-scrollbar {
  width: 10px; /* Ширина полосы прокрутки */
  height: 10px; /* Высота полосы прокрутки (если нужно для вертикальной прокрутки) */
}

/* Стили для трека (фона) полосы прокрутки */
.scroll-container::-webkit-scrollbar-track {
  background-color: rgba(241, 241, 241, 0.5); /* Цвет фона */
  border-radius: 6px; /* Закругление углов */
}

/* Стили для полосы прокрутки */
.scroll-container::-webkit-scrollbar-thumb {
  background-color: #1D4ED8; /* Цвет полосы */
  border-radius: 6px; /* Закругление углов */
  /* width: 10px; */
}

/* Стили для стрелок */
.scroll-container::-webkit-scrollbar-button {
  display: block; /* Показать кнопки */
  background-color: transparent; /* Прозрачный фон */
  position: relative;
}

.scroll-item {
  min-width: 710px;
}

.scroll-item-point {
  border: 2px solid white;
  border-radius: 100px;
  padding: 10px 20px;
  font-size: 36px;
  font-weight: 400;
}

.scroll-item h3 {
  font-size: 36px;
  font-weight: 400;
  display: flex;
  text-align: center;
}

.left-rigth-btn {
  width: 100%;
  height: auto;
  justify-content: space-between;
}

@media (max-width:540px) {
  .advantages-title {
    margin: 0;
  }

  .advantages-title span {
    font-size: 28px;
  }

  .scroll-item {
    min-width: 320px;
    height: auto;
    padding: 15px;
  }

  .scroll-item-point {
    padding: 1vw 2vw;
  }

  .scroll-item h3{
    font-size: 18px;
  }

  .scroll-item ul li {
    font-size: 16px;
  }

  .scroll-container {
    padding-left: 20px;
  }

  .advantages {
    margin-left: 0;
  }
}


/* ecosystem */

.ecosystem-container {
  height: auto;
  margin-bottom: 100px;
}

.ecosystem-add {
  max-width: 1172px;
  min-height: 406px;
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  padding: 140px 0 140px 0;
}

.ecosystem {
  position: relative;
}

.ecosystem::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../img/Background-2.png");
  background-repeat: no-repeat;
  background-size: 35%;
  background-position: center right;
  opacity: 80%;
  z-index: -10;
}

.ecosystem-elems {
  height: 555px;
  background-image: url("../img/Union.png");
  background-repeat: no-repeat;
  background-position: center;
}

.elems-list {
  flex-wrap: wrap;
  gap: 250px;
  --offsets: 1;
  justify-content: space-between;
}

.elems-item {
  max-width: 500px;
  /* outline: 1px solid coral; */
}

.ecosystem-video::after {
  content: "";
  width: 300px;
  height: 300px;
  background-image: url("../img/Video_Background.png");
  background-position: center left;
}

@media (max-width: 1272px) {
  .ecosystem::before {
    display: none;
  }
  
  .ecosystem-add {
    font-size: 36px;
    position: relative;
    padding: 100px 0 100px 0;
    min-height: 300px;
  }

  .ecosystem::before{
    left: 270px;
    background-size:120%;
    background-position: right;
    opacity: 70%;
    z-index: -10;
  }

  .ecosystem-add::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/Union.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0.3; /* Уровень прозрачности изображения */
    transform: rotate(67deg) scale(65%);
  }

  .ecosystem-elems {
    background-image: none;
    height: auto;
  }

  .elems-list {
    gap: var(--gap);
    justify-content: center;
  }

  .elems-item {
    margin: 0;
    padding: 0 !important;
    font-size: 22px;
    max-width: 100%;
  }
}

@media (max-width:540px) {
  .ecosystem-add {
    font-size: 22px;
    position: relative;
    padding: 100px 0 100px 0;
  }

  .ecosystem::before{
    left: 270px;
    background-size:120%;
    background-position: right;
    opacity: 70%;
    z-index: -10;
  }

  .ecosystem-add::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/Union.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0.3; /* Уровень прозрачности изображения */
    transform: rotate(67deg) scale(65%);
  }

  .ecosystem-elems {
    background-image: none;
  }

  .elems-list {
    gap: var(--gap);
  }

  .elems-item {
    margin: 0;
    padding: 0 !important;
    font-size: 18px;
  }

  .advantages-title {
    font-size: 22px;
  }

  .classic-logo {
    display: none;
  }

  .alt-logo {
    display: block;
  }


}


/* video */

.video-descr {
  width: 580px;
  font-size: 16px;
}

.video-play {
  width: 842px;
  height: 456px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.video-play:hover {
  transform: scale(101%);
  color: white;
  opacity: 70%;
}

.video-item-title {
  margin: 0;
  font-size: 64px;
  font-weight: 100;
  text-transform: uppercase;
}

.eco-video-list {
  gap: 18px;
  padding: 140px 0 0 0;
}

.video-img {
  display: none;
  cursor: pointer;
}

.sign {
  justify-content: flex-end;
  text-align: end;
  flex-direction: column;
}

.video-img-none {
  display: none;
}

@media (max-width: 1272px) {
  .video-item-title {
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
  }

  .eco-video-list {
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
  }

  .video-item-descr {
   text-align: start;
  }

  .eco-video-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .eco-video-item p {
    margin: 0;
    padding-top: 18px;
  }

  .eco-video-item span {
    padding-top: 18px;
  }

  .sign {
    justify-content: flex-start;
    text-align: center;
  }

  .video-img {
    display: block;
    padding-top: 16px;
    height: auto;
  }

  .video-play {
    display: none;
  }

  .video-img-none {
    display: none;
  }

}

@media (max-width: 540px) {
  .video-item-title {
    font-size: 28px;
    text-align: center;
    text-transform: uppercase;
  }

  .eco-video-list {
    padding-left: 16px;
    padding-right: 16px;
  }

  .eco-video-item p {
    margin: 0;
    padding-top: 18px;
  }

  .eco-video-item span {
    padding-top: 18px;
  }

  .video-img {
    display: block;
    padding-top: 16px;
  }

  .video-play {
    display: none;
  }
}

/* partners */

.marquee-container {
  width: 100%; /* Ширина контейнера */
  height: 572px;
  padding-top: 100px;
  overflow: hidden; /* Скрытие содержимого, которое не помещается */
}

.marquee-content {
  display: flex;
  align-items: center;
  white-space: nowrap; /* Предотвращение переноса строк */
  animation: marquee-animation   30s linear infinite; /* Анимация бегущей строки */
}

.marquee-img {
  margin-right: 100px;
  align-content: center;
}

@keyframes marquee-animation {
  0% {
    transform: translateX(0%); /* Начальное положение */
  }
  100% {
    transform: translateX(-105%); /* Конечное положение */
  }
}

.partners-title {
  display: block;
  font-size: 64px;
  font-weight: 400;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 50px;
  text-transform: uppercase;
}

@media (max-width: 540px) {
  .partners-title {
    font-size: 28px;
    text-transform: uppercase;
    padding-top: 50px;
  }

  .marquee-container {
    height: 472px;
  }

  .marquee-img {
    transform: scale(70%);
    margin-right: 0;
  }

  .marquee-content {
    animation: marquee-animation 20s linear infinite;
  }

  .youtube-frame {
    width: 320px;
    height: 210px;
  }
}

/* footer */
.footer-section {
  background-image: linear-gradient(to bottom, black 0%, transparent 100%), url("../img/Footer_Gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: auto;
  align-items: flex-end;
}

.footer-container {
  width: 100%;
  padding-top: 103px;
}

.footer-list {
  justify-content: space-between;
  flex-wrap: wrap;
}

.item-list {
  flex-direction: column;
  gap: 20px;
}

.item-title {
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
}

.footer-info-list {
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid white ;
  margin-top: 92px;
  padding-top: 10px;
}

.footer-info-list-add {
  justify-content: space-between;
  margin: 0;
  border: 0;
  padding-top: 20px;
  /* padding-bottom: 63px; */
}

@media (max-width: 675px) {
 .footer-section {
    height: auto;
 }

 .footer-container {
  padding: 0 16px 0 16px;
 }

 .footer-info-list {
  flex-direction: column;
  padding-bottom: 18px;
 }
}

.contacts {
  height: 743px;
}

.contacts-block {
  padding-top: 60px;
  justify-content: space-between;
}

.contacts-info {
  flex-direction: column;
}

.contacts-info-title {
  font-size: 64px;
  margin: 0;
  font-weight: 400;
}

.contacts-info-subtitle {
  font-size: 36px;
  font-weight: 400;
  margin: 0;
}

.contacts-info-block {
  padding-top: 51px;
}

.contacts-info-adress {
  padding-top: 10px;
  font-size: 16px;
  max-width: 586px;
  margin: 0;
}

.contacts-info-block span {
  padding-top: 10px;
}

.modal{
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s;
  z-index: 50;
}


.modal.active {
  opacity: 1;
  pointer-events: all;
  z-index: 999;
}

@media (max-width: 1272px) {
  .contacts-block {
    flex-wrap: wrap;
    padding-top: 40px;
    justify-content: flex-start;
  }

  .map {
    padding-top: 30px;
  }

  .y-maps {
    width: 95vw;
    height: 45vh;
  }

  .contacts {
    height: auto;
    padding-bottom: 70px;
    padding-left: 16px;
    padding-right: 16px;

  }

  .contacts-info-title {
    font-size: 28px;
  }

  .contacts-info-subtitle {
    font-size: 28px;
  }

  .contacts-info-adress {
    font-size: 18px;
  }
}

@media (max-width: 540px) {
  .contacts-block {
    flex-wrap: wrap;
    padding-top: 40px;
    justify-content: center;
  }
}

.img-none {
  display: none;
}

.facecast {
  display: none;
}

.facecast.active {
  display: block;
  width: 100%;
  height: 100%;
}


/* Затемнение всей страницы */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* Затемняем весь экран */
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end; /* Прижимаем модалку вправо */
  align-items: center;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
}

/* Контейнер модального окна (50% на десктопе, 100% в мобильной версии) */
.modal-content {
  background: white;
  padding: 20px;
  position: relative;
  width: 50%; /* По умолчанию половина экрана */
  height: 100vh;
  box-shadow: -5px 0px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  animation: slideIn 0.3s ease-in-out; /* Анимация появления */
}

/* Анимация появления окна справа */
@keyframes slideIn {
  from {
      transform: translateX(100%);
  }
  to {
      transform: translateX(0);
  }
}

/* Iframe внутри модального окна */
.modal-content iframe {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

/* Кнопка закрытия */
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.close-btn:hover {
  color: #1D4ED8;
}

.descr-title-modal {
  font-size: 32px;
}

/* 📱 Адаптивные стили */
@media (max-width: 1024px) {
  .modal-content {
      width: 100%; /* На планшетах и мобильных 100% */
  }
  
  .descr-title-modal {
    font-size: 21px;
  }
}

.iframe-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.3);
  border-top-color: #1D4ED8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal-title {
  text-align: center;
  color: black;
  padding-top: 40px;
  text-transform: uppercase;
  font-size: 32px;
}
